<template>
  <div class="page-content">
    <div class="container">

      <FilterBtnList
        responsive
      ></FilterBtnList>

      <Breadcrumbs :items="breadcrumbsList"></Breadcrumbs>

      <template v-if="loading">

        <v-progress-linear
          indeterminate
        ></v-progress-linear>

      </template>

      <template v-else>
        <div class="panel-request">
          <div class="panel-request__num">Номер заявки <span>№ {{ serviceRequestData.id }}</span></div>

          <ApplicationStatus
            :status="status"
          ></ApplicationStatus>


          <template v-if="provider">
            <div class="panel-request__block">

              <h3 class="panel-request__title">Исполнитель</h3>

              <div class="panel-request__item">

                <BlockPerson
                  class="block-person"
                  :person="provider"
                ></BlockPerson>

              </div>

              <div class="panel-request__row">

                <div class="panel-request__item">
                  <div class="ic-ell">
                    <div class="ic-ell__icon">
                      <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
                    </div>
                    <div class="ic-ell__text">{{ category }}</div>
                  </div>
                </div>

                <div class="panel-request__item">
                  <div class="ic-ell">
                    <div class="ic-ell__icon">
                      <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
                    </div>
                    <div class="ic-ell__text">Дата рождения: {{ provider.birthday }}</div>
                  </div>
                </div>

              </div>

              <div class="panel-request__item" v-if="provider.about">
                <div class="panel-request__about">
                  <div class="panel-request__label">О себе</div>
                  <div class="panel-request__text">{{ provider.about }}</div>
                </div>
              </div>

            </div>
          </template>

          <div class="panel-request__block">
            <h3 class="panel-request__title">Нуждается в помощи</h3>
            <div class="panel-request__item">
              <BlockPerson
                class="block-person--lg"
                :person="relative"
              />
            </div>
            <div class="panel-request__row">
              <div class="panel-request__item">
                <div class="panel-request__field">
                  <div class="panel-request__field-prop">Дата рождения</div>
                  <div class="panel-request__field-value">{{ relative.birthday }}</div>
                </div>
              </div>
              <div class="panel-request__item">
                <div class="panel-request__field">
                  <div class="panel-request__field-prop">Вес</div>
                  <div class="panel-request__field-value">{{ relative.weight }} кг</div>
                </div>
              </div>
            </div>
            <div class="panel-request__row">
              <div class="panel-request__item">
                <div class="panel-request__field">
                  <div class="panel-request__field-prop">Диагноз</div>
                  <div class="panel-request__field-value">{{ relative.reason }}</div>
                </div>
              </div>
              <div class="panel-request__item">
                <div class="panel-request__field">
                  <div class="panel-request__field-prop">Где выполнять услуги</div>
                  <div class="panel-request__field-value">{{ address }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="this.serviceRequestData.about"
              class="panel-request__item"
            >
              <div class="panel-request__descr">
                <div class="panel-request__text">{{ this.serviceRequestData.about }}</div>
              </div>
            </div>
<!--            <ServiceTable-->
<!--              :data="serviceRequestData"-->
<!--            />-->

            <BlockService
              :is-editable="false"
              :service="serviceRequestData"
              @edit-child="openDialogChildRequest"
            />

            <div class="page-content__btn-gp page-content__btn-gp--spb panel-request__btn-grp">

              <router-link
                v-if="
                  status.key === 'ACT' &&
                  isRelativeRequest
                "
                :to="{
                  name: this.$routeName.ASSISTANCE_NEED_RESPONSES,
                  query: { request: this.$route.params.applicationId }
                }"
                class="btn btn--border-acs"
              >Подобрать исполнителя</router-link>

              <button
                v-if="
                  status.key === 'FSD' &&
                  !serviceRequestData.is_rate &&
                  isRelativeRequest
                "
                type="button"
                class="btn btn--border-acs"
                @click="requestRate"
              >Оценить работу</button>

              <button
                v-if="status.key === 'CPD'"
                type="button"
                class="btn btn--border-acs"
                @click="acceptWorkDone"
              >Принять выполненную работу</button>

              <button
                v-if="
                  (status.key === 'ACT' || status.key === 'INP') &&
                  !isRelativeRequest
                "
                type="button"
                class="btn btn--border-acs"
                @click="cancelRequestExecution({ id: null })"
              >Отменить исполнение услуги</button>
              <a
                v-if="status.key === $ENUM.REQUEST_STATUSES.IN_WORK"
                :href="`tel:${provider.mobile}`"
                class="btn btn--prime take-work-btn white"
              >
                {{provider.mobile}}
              </a>
            </div>
          </div>
        </div>
      </template>

    </div>
    <div class="page-content__footer">
      <v-dialog v-model="dialogSupport">
        <template v-slot:activator="{ on, attrs }">
          <div class="page-content__link" v-on="on" v-bind="attrs">Техническая поддержка</div>
        </template>
        <v-card>
          <div class="btn-close" @click="dialogSupport = false">
            <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
          </div>
          <ModalSupport />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>

import FilterBtnList from "@component/Module/NeedServiceRequestList/FilterBtnList";
import BlockPerson from '@component/Module/BlockPerson';
import Breadcrumbs from "@component/UI/Breadcrumbs";
//TODO: ?No more required?
import ModalSupport from "@component/Modal/ModalSupport";
import {mapActions, mapState} from 'vuex';
import ApplicationStatus from "@component/UI/ApplicationStatus";
import {
  ASSISTANCE_NEED_APPLICATION,
  ASSISTANCE_NEED_APPLICATIONS,
  ASSISTANCE_NEED_RESPONSES
} from "@router/variable";
import BlockService from "@component/Module/BlockService";

import { preparePhoto } from '@library/helpers';

export default {
  name: "NewApplication",
  components: {
    BlockService,
    ApplicationStatus,
    BlockPerson,
    FilterBtnList,
    ModalSupport,
    Breadcrumbs
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      categories: state => state.enums.categories,
      statuses: state => state.enums.requestsStatuses,
    }),
    isRelativeRequest() {
      return !!this.serviceRequestData.child.length;
    },
    status() {
      return this.statuses.find(status => status.key === this.serviceRequestData?.status);
    },
    provider() {
      if (!this.serviceRequestData.accepted_response) return;

      const { user, price, accepted_date } = this.serviceRequestData.accepted_response;
      const {
        first_name,
        last_name,
        birthday,
        photo,
        about,
        rate_avg: rate,
        mobile
      } = user;

      return {
        name: `${first_name} ${last_name}`,
        date: DateTime(accepted_date, 'DD.MM.YYYY HH:mm:ss').format('DD MMMM YYYY, HH:mm'),
        image: preparePhoto(photo),
        birthday,
        price,
        about,
        rate,
        mobile
      };
    },
    author() {
      const { first_name, last_name, photo } = this.user;
      return {
        name: `${first_name} ${last_name}`,
        date: DateTime(this.serviceRequestData.created_at).format('DD MMMM YYYY HH:mm'),
        image: preparePhoto(photo)
      };
    },
    category() {
      return this.serviceRequestData?.category?.name ?? '';
    },
    relative() {
      const family = this.serviceRequestData.family;

      if (family) {
        const {
          id,
          first_name,
          last_name,
          photo,
          birthday,
          weight,
          reason,
        } = family;



        return {
          id,
          name: `${first_name} ${last_name}`,
          image: preparePhoto(photo),
          birthday,
          weight,
          reason
        };

      }
      return {};
    },
    address() {
      return this.serviceRequestData?.address?.address ?? '';
    }
  },
  data() {
    return {
      loading: true,
      serviceRequestData: {},
      rateVue: 4,
      dialogSupport: false,
      dialogEvaluate: false,
      breadcrumbsList: [
        {
          text: 'Все заявки',
          disabled: false,
          exact: true,
          to: { name: this.$routeName.ASSISTANCE_NEED_APPLICATIONS },
        },
        {
          text: `Заявка № ${this.$route.params.applicationId}`,
          exact: true,
          to: { name: this.$routeName.ASSISTANCE_NEED_APPLICATION, params: { applicationId: this.$route.params.applicationId } },
        },
      ]
    };
  },
  methods: {
    ...mapActions('enums', ['getRequestsStatuses']),
    async onFinishChildRequest({ id, done }) {
      try {
        this.$modal.show(
          'Confirm',
          {
            title: 'Вы подтверждаете, что работа выполнена?',
            text: ''
          }
        ).then(async (res) => {
          if(!res) return;
          await RequestManager.Service.customerCompleteWork(this.user.id, id)
            .then(() => {
              const index = this.serviceRequestData.child.findIndex(request => request.id === id);
              if (index + 1 > 0) {
                this.serviceRequestData.child[index].status = this.$ENUM.REQUEST_STATUSES.FINISHED;
              }
              const statuses = this.serviceRequestData.child.map(({ status }) => status);
              const { IN_WORK, FINISHED } = this.$ENUM.REQUEST_STATUSES;
              if (!statuses.includes(IN_WORK)) {
                this.$set(this.serviceRequestData, 'status', FINISHED);
              }
            });
        });

      } catch (err) {
        this.$modal.show('ErrorMessage');
      } finally {
        done();
      }
    },
    openDialogChildRequest(id) {

      let childServiceRequestIndex = this.serviceRequestData.child.findIndex(serviceRequest => serviceRequest.id === id);

      this.$modal.show('ChildRequest',
        {
          parentRequestId: this.serviceRequestData.id,
          request: this.serviceRequestData.child[childServiceRequestIndex]
        },
        {
          'success-child-update': () => this.$modal.hide('ChildRequest'),
          'onFinishWork': this.onFinishChildRequest,
          'onRateWork': this.requestRate,
          'onCancelWork': this.cancelRequestExecution
        }
      )
        .then((response) => {
          this.$set(this.serviceRequestData.child, childServiceRequestIndex, response);
        });
    },
    cancelRequestExecution({ id = null, done = () => {}}) {
      this.$modal.show(
        'Confirm',
        {
          title: 'Вы уверены что хотите отменить исполнение услуги?',
          text: ''
        }).then((res) => {
          if (res) {
            const requestId = id ?? this.$route.params.applicationId;
            RequestManager.Service.cancelRequestExecution(
              this.user.id,
              requestId
            )
              .then(() => {
                const { CANCELED } = this.$ENUM.REQUEST_STATUSES;
                const index = this.serviceRequestData.child.findIndex(request => request.id === id);
                if (index + 1 > 0) {
                  this.serviceRequestData.child[index].status = CANCELED;
                }
                const statuses = this.serviceRequestData.child.map(({ status }) => status);
                if (!this.serviceRequestData.child.length || statuses.every((status) => status === CANCELED)) {
                  this.$set(this.serviceRequestData, 'status', CANCELED);
                }
              })

              .catch(err => {
                this.$modal.show('ErrorMessage', { text: err.getDetails().axiosErrorObject.response.data });
              });
            this.$modal.hide('Confirm');
          }
      });
    },
    async acceptWorkDone() {
      try {
        this.$modal.show(
          'Confirm',
          {
            title: 'Вы подтверждаете, что работа выполнена?',
            text: ''
          }).then(async res => {
            if(!res) return;
            await RequestManager.Service.customerCompleteWork(this.user.id, this.$route.params.applicationId);
            this.serviceRequestData.status = "FSD";
            await this.requestRate();
        });

      } catch (err) {
        this.$modal.show('ErrorMessage');
      }
    },
    requestRate() {
      this.$modal.show(
        'Evaluate',
        {}
      ).then(res => {
        if (res) {
          RequestManager.Service.rateRequest(
            this.user.id,
            this.$route.params.applicationId,
            {
              user: this.serviceRequestData.accepted_response.user.id,
              rate: res
            }
          )
            .then(() => {
              this.serviceRequestData.is_rate = true;
            })
            .catch(err => {
              console.error(err);
            });
          this.$modal.hide('Evaluate');
        }
      });
    }
  },
  async mounted() {
    await this.$store.dispatch('enums/getCategories');
    await this.getRequestsStatuses();
    const { applicationId } = this.$route.params;
    this.serviceRequestData = await RequestManager.Service.getRequest(this.user.id, applicationId);
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>

// panel-request
.panel-request {
  position: relative;
  margin-bottom: 40px;
  padding: 12px 27px 21px;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 1px solid rgba(118, 118, 128, 0.12);
  .panel-request__row {
    @include respond-to(md) {
      display: flex;
      width: 100%;
    }
    .panel-request__item {
      width: 100%;
      @include respond-to(md) {
        flex: 1;
      }
      &:nth-of-type(1) {
        @include respond-to(md) {
          margin-right: 28px;
        }
      }
    }
  }
  .panel-request__btn-grp {
    margin-top: 34px;
    justify-content: center;
  }
  .request-status {
    position: absolute;
    top: 12px;
    right: 30px;
  }
  .block-person {
    margin-top: 10px;
    margin-bottom: 18px;
  }
  .panel-request__num {
    margin-bottom: 9px;
    color: rgba(2, 2, 2, 0.3);
    font-size: 15px;
    span {
      color: #020202;
    }
  }
  .panel-request__title {
    margin-bottom: 19px;
    color: #020202;
    font-size: 17px;
    font-weight: 600;
  }
  .panel-request__value {
    color: #0C76DA;
    font-size: 15px;
  }
  .panel-request__label {
    margin-bottom: 15px;
    color: #000000;
    font-size: 17px;
  }
  .panel-request__text {
    color: rgba(0, 0, 0, 0.4);
    font-size: 17px;
  }
  .panel-request__block {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    .panel-request__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(163, 163, 163, 0.3);
    }
  }
  .panel-request__about {
    padding: 20px 0 10px;
  }
  .panel-request__descr {
    padding: 16px 0 10px;
  }
  .ic-ell {
    padding: 16px 0;
  }
  .panel-request__field {
    padding: 11px 0;
    .panel-request__field-prop {
      margin-bottom: 6px;
      color: rgba(0, 0, 0, 0.4);
      font-size: 15px;
    }
    .panel-request__field-value {
      color: #000000;
      font-size: 17px;
    }
  }

  .btn {
    color: inherit;

    &:hover {
      color: #FFFFFF;
    }
  }
  a.btn.white {
    color: #ffffff;
  }
}

::v-deep {
  .block-service {
    margin-top: 16px;
  }
}

</style>
